import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { WritingInfo } from "../helpers/WritingInfo";
import { SincerlySpeaking } from "../helpers/SincerlySpeaking";
import "../styles/WritingDetail.css";

function WritingDetail() {
  const { writingName } = useParams();
  const navigate = useNavigate();
  const writing =
    WritingInfo.find((w) => w.name === writingName) ||
    SincerlySpeaking.find((s) => s.name === writingName);

  useEffect(() => {
    // Prevent page scrolling
    document.body.style.overflow = "hidden";
    return () => {
      // Re-enable page scrolling when component unmounts
      document.body.style.overflow = "auto";
    };
  }, []);

  if (!writing) {
    return <div>Writing not found</div>;
  }

  const renderParagraph = (paragraph, isReference) => {
    if (!paragraph) return null;
    if (isReference) return paragraph; // Skip superscripting for references
    const parts = paragraph
      .split(/(\d+:\d+(-\d+)?|\d+-\d+|\d+)/)
      .filter(Boolean);
    return parts.map((part, index) =>
      /\d+:\d+(-\d+)?|\d+-\d+/.test(part) ? (
        part
      ) : /^\d+$/.test(part) ? (
        <sup key={index}>{part}</sup>
      ) : (
        part
      )
    );
  };

  const handleNextChapter = () => {
    console.log("test");
    const currentIndex = SincerlySpeaking.findIndex(
      (s) => s.name === writingName
    );
    if (currentIndex !== -1 && currentIndex < SincerlySpeaking.length - 1) {
      const nextChapter = SincerlySpeaking[currentIndex + 1];
      navigate(`/writings/${nextChapter.name}`);
      setTimeout(() => {
        document
          .querySelector(".writing-detail-content")
          .scrollTo({ top: 0, behavior: "smooth" });
      }, 0); // Ensure the scroll happens after navigation
    }
  };

  const handleBackClick = () => {
    navigate("/writings");
  };

  return (
    <div className="writing-detail">
      <div className="writing-detail-content">
        <div className="header">
          <button className="back-button" onClick={handleBackClick}>
            ← Back to Writings
          </button>
          <h1 className="writing-title">{writing.name}</h1>
          <hr className="divider" />
          {writing.type === "movies" && writing.subtitle && (
            <h2 className="subtitle">{writing.subtitle}</h2>
          )}
        </div>
        <div
          className={`writing-content ${
            writing.type === "poems" ? "poems" : ""
          }`}
          style={{
            marginTop: writing.type === "sincerly speaking" ? "40px" : "0",
            marginBottom: writing.type !== "sincerly speaking" ? "300px" : "0",
          }}
        >
          {writing.type === "poems"
            ? writing.paragraphs.map((stanza, index) => (
                <div key={index} className="stanza">
                  {stanza.map((line, lineIndex) => (
                    <p key={lineIndex} className="poem-line">
                      {line}
                    </p>
                  ))}
                </div>
              ))
            : writing.content
            ? Array.isArray(writing.content[0])
              ? writing.content.map((paragraphs, index) => (
                  <div key={index} className="section">
                    {paragraphs.map((paragraph, pIndex) => (
                      <p key={pIndex} className="indent">
                        {renderParagraph(paragraph)}
                      </p>
                    ))}
                  </div>
                ))
              : writing.content.map((section, index) => (
                  <div key={index} className="section">
                    <h2>{section.subtitle}</h2>
                    {section.paragraphs.map((paragraph, pIndex) => (
                      <p
                        key={pIndex}
                        className={
                          paragraph.type === "quote"
                            ? "quote"
                            : paragraph.type === "noIndent"
                            ? "noIndent"
                            : "indent"
                        }
                      >
                        {renderParagraph(
                          paragraph.text,
                          section.subtitle === "References"
                        )}
                      </p>
                    ))}
                  </div>
                ))
            : Object.values(writing.paragraphs).map((paragraph, index) => (
                <ReactMarkdown key={index}>{paragraph}</ReactMarkdown>
              ))}
        </div>
        {writing.references && (
          <div className="writing-references">
            <h2>References</h2>
            <ul>
              {writing.references.map((ref, index) => (
                <li key={index}>{renderParagraph(ref, true)}</li>
              ))}
            </ul>
          </div>
        )}
        {writing.type === "sincerly speaking" && (
          <button className="next-chapter-button" onClick={handleNextChapter}>
            Next Chapter →
          </button>
        )}
      </div>
    </div>
  );
}

export default WritingDetail;
