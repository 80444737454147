import React, { useEffect } from "react";
import "../styles/About.css";

function About() {
  useEffect(() => {
    // Set overflow to hidden when the component mounts
    document.body.style.overflow = "hidden";

    // Reset overflow to auto when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <section className="about">
      <div className="headerWrapper">
        <div className="headerContainer">
          <h1>About</h1>
          <p>
            Over the past few years, writing has become a powerful way for me to
            process my thoughts and explore ideas I’m passionate about. This
            passion, paired with a deep desire to share the gospel and explore
            biblical ideals, has inspired me to write about the books I read,
            the movies I watch, the lessons I’ve taught, and the topics I
            research. I hope these writings invite you to explore new ideas and,
            above all, give you a glimpse of Christ’s love.
          </p>
          <p>
            In writing about movies, my goal is to delve into their themes and
            messages, considering how the lessons within them can be aligned
            with biblical truths and applied to our daily lives. I hope these
            insights will offer a way to turn conversations toward Jesus
            naturally.
          </p>
          <p>
            I explore the many facets of faith through poetry, inspired by the
            Psalms and their honest expressions to God. I aim to model this
            openness in my poems, trusting anyone who reads them might find
            comfort or encouragement in their journey.
          </p>
          <p>
            In all my writings, I intend to share the thoughts and questions
            that fill my mind on these topics. My website, “Pay Forward,”
            reflects what I believe to be a calling for all Christians—to use
            our God-given gifts to share the love we have received. I hope that,
            in reading here, you feel encouraged to find your own passions and
            use them in ways that point back to Jesus.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
