import { type } from "@testing-library/user-event/dist/type";

export const SincerlySpeaking = [
  {
    name: "Acknowledgements",
    type: "sincerly speaking",
    content: [
      [
        "This short book was a personal project that I’ve grown to be passionate about throughout the last year of my life. It began as a way to process the theme of authenticity that I had noticed throughout the Biblical narrative. Eventually, it became a deep desire to truly understand my faith, better comprehend the Bible, and process the important themes God tells us through His Word. However, none of this would be possible without the community of believers around me who have consistently poured into my life, have been patient with me, and have pushed me to grow in every aspect of my life.",
        "Thank you to my family, mother, father, and brother, for being a rock. Thank you, Phil Baker, for spending time with me and constantly asking questions challenging my faith. Thank you, Olson, for your frequent listening ear and for being one of the most inspiring Christians in my life. Thank you to the community who has inspired me to look toward God in every way, every day. I am endlessly thankful for the meaningful individuals and fellowship God has placed in my life. I feel truly blessed by my community.",
      ],
    ],
  },
  {
    name: "Introduction",
    type: "sincerly speaking",
    content: [
      [
        "I genuinely believe that no matter the situation in life, growth will happen if you allow it. Similar to a board game, life is like a marble, advancing and maneuvering around the board, making its way “home”; sometimes the marble is moving quickly, other times moving slowly, or occasionally it is being stopped by obstacles along the way. Simply put, life is hectic and messy; the Christian walk is not so dissimilar. Faith is a journey; just like any good adventure story, there will be many trials, tribulations, beauty, and blessings along the way. I’ve witnessed this faith journey in my life and have heard endless life stories from people around me.",
        "It is important for me to mention that this book is not meant to be an apologetical argument for the faith but to share the story of many Christians and Biblical characters who may help one understand that it is normal to question God’s goodness, to doubt God’s plans, to be frustrated beyond comprehension, and to feel utterly hopeless to the point of death. However, as Christians, we are faced with a choice of how to act when these bitter and hopeless feelings arise. There is a Biblical calling for those who believe to behave in a reflective manner that views one’s current circumstances in a way that challenges them to grow. Throughout all the obstacles and pitfalls, there is hope, joy, and an endless amount to be thankful for. As Christians, we are not alone in our faith. However, to understand our own faith journey, it is important to understand God’s goodness.",
      ],
    ],
  },
  // Chapter 1
  {
    name: "Chapter 1: Questioning Goodness",
    type: "sincerly speaking",
    content: [
      {
        paragraphs: [
          "“I do not question God’s goodness and love towards the people in my life. I know God loves my wife, I know God loves my children, I know God loves you, but I still doubt that God loves me.” To this day, these simple yet profound words struck a chord in my heart when talking to a mentor of mine about God’s goodness and love. I believe it is fair to say that many Christians struggle to see God’s goodness, especially in their own life. So, what is God’s “goodness”? It is His will to allow all who choose to submit to His love, grace, and mercy to bring us closer to Him and eventually be with Him forever. His goodness is what He has done for us in Jesus’ sacrifice so that we could be with Him for eternity. Additionally, it is His active will to redeem His creation and to sanctify those who believe.",
          "If Christians believe in God’s love and goodness for others, why would they fail to see that same goodness and love bestowed upon them? Why do these individuals exclude themselves from the love of God?",
        ],
      },
      {
        subtitle: "Unmet Personal Expectations",
        paragraphs: [
          {
            text: "I understand the sentiment of feeling as if God is absent, has afflicted me, or is not working in my life in a way that I deem suitable. Many biblical writers can empathize with this feeling. Moses, God’s appointed leader of His called people, became frustrated at God because of incessant complaints from the Israelites. We see Moses break down and cry out to God, saying: ",
          },
          {
            text: "'Why have you dealt ill with your servant? And why have I not found favor in your sight, that you lay the burden of all this people on me... Where am I to get meat to give all this people? For they weep before me and say, “Give us meat, that we may eat.” I am not able to carry all this people alone; the burden is too heavy for me. If you will treat me like this, kill me at once, if I find favor in your sight, that I may not see my wretchedness.'1",
            type: "quote",
          },
          {
            text: "Clearly, Moses feels like God has cursed him with an unachievable task.",
            type: "noIndent",
          },
          {
            text: "Another example of this can be found with David, the man after God’s own heart, who even felt abandoned by God. He questions God, saying, “How Long, O Lord? Will you forget me forever? How long will you hide your face from me?”2 To put this into context, David was promised to be the next king of Israel but was now running for his life because the current king, Saul, sought to end his life. It would only make sense that he feels abandoned by God. Additionally, it is reasonable to presume that David may have felt as if he was being deceived, mistreated, or ignored by God.",
          },
          {
            text: "Both Moses and David had ideas or expectations about how they believed God should have responded to their situations. Their unmet expectations created frustration and tension.",
          },
        ],
      },
      {
        subtitle: "The Weeping Prophet",
        paragraphs: [
          {
            text: "“Now the word of the Lord came to [Jeremiah], saying ‘Before I formed [Jeremiah] in the womb I knew [Jeremiah] and before [he] was born I consecrated [Jeremiah]; I appointed [Jeremiah] a prophet to the nations.’” 3 In Jeremiah’s youth God would tell him, “... for to all to whom I send you, you shall go, and whatever I command you, you shall speak. Do not be afraid of them, for I am with you to deliver you, declares the LORD.” 4 This promise from God would establish a strong relationship between the two and begin Jeremiah’s calling. Jeremiah would lean heavily on the promise that God would always be there for him as God would call Jeremiah to rebuke His nation and people. These commands to repent served as warnings to the Israelites of incoming destruction due to their rebellious ways. As expected, Jeremiah would be met with much criticism and conflict, but on top of it all, he would have to watch his fellow Israelites constantly disobey and disown the Lord.",
          },
          {
            text: "Jeremiah would cry to God in his despair and frustration, saying, “Righteous are you, O LORD when I complain to you; yet I would plead my case before you. Why does the way of the wicked prosper? Why do all who are treacherous thrive? … Pull them out like sheep for the slaughter, and set them apart for the day of slaughter.” 5 He would further declare, “O Lord, you have deceived me, and I was deceived…” 6 Clearly, Jeremiah has developed an expectation that what God told him to say would cause some form of immediate redemption or justice.",
          },
          {
            text: "It makes sense why Jeremiah would be upset. He is watching injustice run rampant among his people and doesn’t understand why God isn’t doing something about it. At that moment, Jeremiah feels God is not being “good” or equitable; instead, he seems malicious and cruel. There is immense confusion and pleading for God to act as Jeremiah feels necessary, and many readers can empathize with the prophet's weeping replies to God’s response.",
          },
        ],
      },
      {
        subtitle: "Failing To See",
        paragraphs: [
          {
            text: "The stories of Moses, David, and Jeremiah are relatable because there is a level of desire and expectation that taints their perception of God’s timing, will, and goodness. For Moses, he felt he was being used to bear the burdens of the Israelites, and God had accursed him with leading an insufferable people out of Egypt. Moses questioned God’s choice while forgetting His sovereignty. He believed God had abandoned him in the process of saving the Israelites. David expected a promise to be fulfilled. Without seeing the fulfillment of the promise in his timing, he doubted the validity of God’s word. Jeremiah was left with an unmet expectation that God would quickly act in a just manner for the redemption of His people and responded with immense confusion and concern.",
          },
          {
            text: "All three figures failed to remember what God had previously done in their lives. Moses had forgotten that God had saved the Israelites from Egypt and repeatedly brought them out of their conundrums. God fought with David in his battle against Goliath and provided provision through his best friend, Johnathan, who had been there for him throughout many hardships. In addition, David had the benefit of history and hindsight by recalling how God had provided for Moses and kept His promises to the people of Israel. For Jeremiah, it was the reminder that despite the Jews' countless years of heinous acts, God had not annihilated them. He struggled to grasp God's willingness to offer His people yet another chance to repent. All three men failed to recall what God had done, which resulted in a lapse of trust in God. How often do we forget what God has done in our lives and then act out in an unfaithful and distrustful manner? As Christians, we frequently overlook the core of what God has done for us. Through His death and resurrection, Christ has redeemed us. The immense sacrifice of His death has blessed us beyond comprehension, yet we fail, as Christians, to remember this fundamental promise and blessing. Not only did He send His Son to die for us, but we also have a God who will forgive all our faults, failures, and shortcomings. Paul says it best in Romans 5:8, “but God shows his love for us in that while we were still sinners, Christ died for us.”",
          },
          {
            text: "The cross of Christ does not diminish the fact that there is endless hurt, suffering, and unknowns, which often make it easy to question God’s goodness and plan. When questioning Him, we must guard against an arrogant attitude that disregards our flawed nature. Our inability to know everything can result in pride that expunges our understanding of God's sheer splendor and wisdom over all creation.",
          },
          {
            text: "I believe everyone, to some capacity, has felt overlooked by God. If we are being honest with ourselves, we often believe that our opinion on how God should act is more important than God’s plan and judgment. It is somewhat ignorant to say that we would have thought and behaved differently than Moses, David, and Jeremiah did in their circumstances.",
          },
        ],
      },
      {
        subtitle: "Questions To Ask Yourself",
        paragraphs: [
          {
            text: "●	What does God’s goodness mean to you?",
            type: "question",
          },
          {
            text: "●	Do you wrestle with questions like,	why does God let ‘good’ people die, why is there so much suffering in the world, why is there so much ‘church hurt’?",
            type: "question",
          },
          {
            text: "●	Do you see blessings in other people’s lives and become envious?",
            type: "question",
          },
          {
            text: "●	Do you believe God has given up on you, abandoned you, or forgotten you?",
            type: "question",
          },
          {
            text: "●	Do you truly believe God’s plan is good?",
            type: "question",
          },
          {
            text: "●	Where have you seen God clearly work in your life?",
            type: "question",
          },
          {
            text: "●	Can you find peace without knowing all the intricacies of God’s plan?",
            type: "question",
          },
        ],
      },
      {
        subtitle: "References",
        paragraphs: [
          { text: "1.	Numbers 11:10-15" },
          { text: "2.	Psalm 13:1" },
          { text: "3.  Jeremiah 1:4-5" },
          { text: "4.  Jeremiah 1:7-8" },
          { text: "5.  Jeremiah 12:1,3" },
          { text: "6.  Jeremiah 20:7a" },
        ],
      },
    ],
  },
  // Chapter 2
  {
    name: "Chapter 2: Pride's Role in Our View of God",
    type: "sincerly speaking",
    content: [
      {
        paragraphs: [
          {
            text: "“Pride goes before destruction, and a haughty spirit before a fall.”1 This idea can be seen in the story with the king of Tyre (or potentially Satan). The passage, “Your heart was proud because of your beauty; you corrupted your wisdom for the sake of your splendor. I cast you to the ground,” 2 seem to allude to the fact that the king, or Satan, fell because of prideful character. Whether the passage is about the king of Tyre, Satan, or both does not inherently matter. Rather, it is evidence of the dangers of pride. In reference to Satan, this would suggest that pride was the first action against God. If pride is so significant, then it should be a focal point in our introspection.",
          },
        ],
      },
      {
        subtitle: "The Untouchable Right to Pride",
        paragraphs: [
          {
            text: "So, how do we define pride? According to Merriam-Webster, pride is exaggerated self-esteem or conceit.3  While conceit is an excessive appreciation of one’s own worth or virtue.4 So, coupling the definitions together would yield pride as an excessive view of one’s opinions, image, value, and role. I believe it is necessary to understand our place as God’s creation and as God’s called people, for we are called to be a way that God can demonstrate His love to creation. However, we must also embrace how minuscule we are within the grand scheme of it all. This can be clearly seen in the book of Ecclesiastes, but specifically in the first chapter, “... All is vanity.”5 Another way to translate the word vanity would be futile, empty, pointless, or unsatisfactory. This explicit statement quickly raises the question, “why is everything pointless?” which is then answered immediately: ",
          },
          {
            text: "A generation goes, and a generation comes, but the earth remains forever. The sun rises, and the sun goes down, and hastens to the place where it rises…6 Is there a thing in which it is said, ‘See, this is new’? It has already been ages before us. There is no remembrance of former things, nor will there be any remembrance of later things yet to be among those who come after. 7",
            type: "quote",
          },
          {
            text: "To put it bluntly, we are insignificant in the grand scheme of it all. If you want practical proof of this point, ask yourself how much you know about your great-grandparents. Take it a step further: how much do you know about your great-great-grandparents? Most likely, the answer is that you know incredibly little about them.",
            type: "noIndent",
          },
          {
            text: "Three or four generations away, family becomes irrelevant. Thus, if one’s own family cannot stay relevant, why should we, as Christians, strive for relevance? What makes us believe we will be any bit more relevant than the vast majority of individuals in history? The story of Job and his dialogue with God gives us a humbling way to view our lives.",
          },
          {
            text: "Job, a man who had just lost his abundant wealth and legacy, cries out to God. Attempting to rationalize and grieve his immense losses, Job is greeted with consultation from God. In this confrontation, Job is quickly humbled and better understands why his perception is microscopic compared to God's.",
          },
          {
            text: "Where were you when I laid the foundation of the earth? Tell me, if you have understanding. Who determined its measurements – surely you know! Or who stretched the line upon it… Have you comprehended the expanse of the earth… Can you bind the chains of the Pleiades or loose the cords of Orion? Can you lead the Mazzaroth in their season, or can you guide the Bear with its children, do you know the ordinances of the heavens? Can you establish their rule on the earth… Do you give the horse his might? Do you clothe his neck with a mane… Is it at your command that the eagle mounts up and makes his nest on high… Shall a faultfinder contend with the Almighty? 8",
            type: "quote",
          },
          {
            text: "These questions from God bring to attention the vastness of His understanding while giving Job a reality check on his place within the universe.",
          },
          {
            text: "The famous scientist and philosopher Blaise Pascal once said, “If man made himself the first object of study, he would see how incapable he is of going further. How can a part know the whole?” 9 How can Job, a small part within the grand scheme of it all, understand the intricacies of God? How can we understand every nuance and decision God makes? Job, having the unique experience of seeing a small portion of God’s perspective, quickly repents, saying: ",
          },
          {
            text: "I know that you can do all things and that no purpose of yours can be thwarted. ‘Who is this that hides counsel without knowledge?’ Therefore I have uttered what I did not understand, things too wonderful for me, which I did not know’... I had heard of you by the herding of the ear, but now my eye sees you; therefore, I despise myself, and repent in the dust and ashes.10",
            type: "quote",
          },
          {
            text: "From these two passages, we need to recognize how small we are, which requires humility. John Flavel, a 17th-century Puritan pastor, once said, “They that know God will be humble, and they that know themselves, cannot be proud,”11 which is an elegant way of explaining the two-parted process of recognizing our diminutiveness and understanding God's immeasurable understanding, love, and plan for us.",
          },
        ],
      },
      {
        subtitle: "Our Limited Perspective",
        paragraphs: [
          {
            text: "Pride manifests itself in many forms; one of those ways includes endlessly questioning God’s goodness. This looks like constantly trying to find a reason to doubt, disbelieve, or relentlessly trying to figure out the reasoning behind God’s decisions. If one is doing so, one may not recognize and accept the inherent significance of God’s will. What do I mean by that? While it is important to be honest with God and bring to Him your frustration, anger, and confusion (more on that later), there comes a point when one should accept that he or she will never understand the complete picture. This acceptance requires humbling oneself before God and coming to terms with the fact that there is a greater goal, God’s bigger picture, in progress.",
          },
          {
            text: "Endless questioning of God’s goodness might imply that one believes one could do a better job than God. Or that one’s own will is far superior to God’s. For example, a gentleman in his sixties is on his deathbed sickened with cancer. His daughter, frustrated and upset, does not understand why her father, who everyone in the community loved, is going to die. In her frustration, she cries out to God, saying, “he deserved to live longer, why would you take him away from me.” While the daughter is understandably frustrated and upset, her pleading inherently ignores many key concepts we, as Christians, subscribe to. The daughter believes that her timeline of when her father should pass away is better than God’s timeline. In essence, she presumes she has the right to decide life and death rather than trusting and believing God has all the authority to decide the timing of when her father should go.",
          },
          {
            text: "Another example to potentially explain the difference in perspective between God and His creation is used in Lee Strobel’s book The *Case For Faith*. Strobel interviews Catholic philosopher Peter John Kreeft, asking why he believes acting like we know God’s full plan is arrogant. Kreef says, “How can a mere finite human be sure that infinite wisdom would not tolerate certain short-range evils in order for more long-range goods that we couldn’t foresee?” He continued to expound on this idea by telling a story of a bear and a hunter.",
          },
          {
            text: "A hunter wants to help a bear stuck in a trap. The hunter tries to help by approaching the bear and freeing it from the trap, but the bear is upset and believes the hunter is attacking. The hunter, in his continued efforts, tries to help by shooting the bear with a tranquilizer, but the bear thinks the hunter is still attacking him. Again, the hunter helps the bear after it has been tranquilized and successfully moves it. If the bear had not been tranquilized, it would have been upset again. The bear represents us humans, while the hunter represents God. The bear’s perception may feel justified, but it does not know the hunter's full picture and goal.12",
          },
        ],
      },
      {
        subtitle: "What Shall We Do?",
        paragraphs: [
          {
            text: "As seen in Ecclesiastes and the dialogue with Job, our role while on this earth is minuscule. We are not physically, mentally, or emotionally meant to understand the finite details regarding God’s will, but instead submit to the loving authority of our creator. As followers, we are called to act pleasing to God: justly, caring, honorable, loving, supportive, and humble. In Proverbs 31:8-9, there is a clear calling: “Open your mouth for the mute, for the rights of all who are destitute. Open your mouth, judge righteously, defend the rights of the poor and needy.” Isaiah 1 furthers the idea by saying, “... cease to do evil, learn to do good; seek justice, correct oppression; bring justice to the fatherless, plead the widow’s cause.”13 There is a clear calling to pursue justice, be supportive, and care for those in need.",
          },
          {
            text: "The Apostle Paul says, “For the kingdom of God is … of righteousness and peace and joy in the Holy Spirit. … So then let us pursue what makes for peace and mutual upbuilding.”14 This is followed up by Paul in Ephesians saying, “I, therefore a prisoner for the Lord, urge you to walk in a manner worthy of the calling to which you have been called, with all humility and gentleness, with patience, bearing with one another in love, eager to maintain the unity of the Spirit in the bond of peace.”15 The book of Philippians encourages this idea of loving the community that we are a part of by saying,",
            type: "quote",
          },
          {
            text: "The goal of showing these passages is to give a brief overview of our calling as Christians and to show a common theme among the passages. These verses have nothing to do with our attempt to understand God’s immediate plan of action but rather call us to be humble, self-sacrificial, and to trust in God’s plan and provision. Christ says these powerful words, “But seek first the kingdom of God and his righteousness, and all these things will be added to you. Therefore do not be anxious about tomorrow, for tomorrow will be anxious for itself. Sufficient for the day is its own trouble.”17 Trying to understand the nuances of why God does things the way He does is a waste of the resources He has given us to act on our true Christian calling.",
          },
          {
            text: "We are all flawed humans, and a laundry list of situations makes it difficult to understand God’s plan. In our confusion, we need to be cautious of the anger, frustration, and discontentment that can arise and lead to a disgruntled relationship with God. As Christians, it is better to spend more of our time focusing on Him while trusting that He will work out His will in His time.",
          },
        ],
      },
      {
        subtitle: "Questions To Ask Yourself",
        paragraphs: [
          {
            text: "●	Where in your life are you prideful?",
            type: "question",
          },
          {
            text: "●	What do you place your confidence in?",
            type: "question",
          },
          {
            text: "●	Where do you believe humility comes from?",
            type: "question",
          },
          {
            text: "●	In what circumstances do you doubt God’s plan?",
            type: "question",
          },
          {
            text: "●	Have you ever misinterpreted circumstances to later realize God’s greater plan?",
            type: "question",
          },
          {
            text: "●	How worried are you about the immediate future?",
            type: "question",
          },
        ],
      },
      {
        subtitle: "References",
        paragraphs: [
          { text: "1.	 Proverbs 16:18" },
          { text: "2.	 Ezekiel 28:17" },
          {
            text: "3.  Merriam-Webster. (n.d.). Pride. In Merriam-Webster.com dictionary. Retrieved February 5, 2023, from https://www.merriam-webster.com/dictionary/pride",
          },
          {
            text: "4.	 Merriam-Webster. (n.d.). Conceit. In Merriam-Webster.com dictionary. Retrieved February 5, 2023, from https://www.merriam-webster.com/dictionary/conceit",
          },
          { text: "5.  Eccelesiastes 1:2" },
          { text: "6.  Eccelesiastes 1:4-5" },
          { text: "7.  Eccelesiastes 1:10-11" },
          { text: "8.  Job 38-40" },
          {
            text: "9.  Pascal, B. (1958). Pensées (Section II, 72). New York: E.P. Dutton.",
          },
          { text: "10.  Job 42:2-6" },
          {
            text: "11.	Gilbert, J. H. (2014). Dictionary of burning words of brilliant writers (p. 329). Literary Licensing, LLC.",
          },
          {
            text: "12.	Strobel, L., & Hanegraaff, H. (2000). The Case for Faith: A Journalist Investigates the Toughest Objections to Christianity. ZondervanPublishingHouse.",
          },
          { text: "13.  Isaiah 1:16-17" },
          { text: "14.  Romans 14:17-19" },
          { text: "15.  Ephesians 4:1-6" },
          { text: "16.  Philippians 2:1-4" },
          { text: "17.  Matthew 6:33-34" },
        ],
      },
    ],
  },
  // Chapter 3
  {
    name: "Chapter 3: Anger",
    type: "sincerly speaking",
    content: [
      {
        paragraphs: [
          {
            text: "John Climacus accurately describes the powerful emotion of anger when he says, “Just as the winds whip up the sea, so does anger stir confusion in the mind.” 1 This emotion can be just as damaging to oneself as to others. Anger is not often directly taught to us but is experienced by everyone. It does not discriminate, and it can even be contagious.",
          },
          {
            text: "So, where does it come from? Typically, anger results from an unmet expectation, which causes a feeling that someone or something has wronged you. It can also result from hurt, perpetual pain, grief, or frustration caused by a missed opportunity. Being wronged in some way may lead to feelings of hurt and eventual anger. Anger often becomes a narrow-minded mentality that diminishes the larger picture. As alluded to earlier, anger confuses the mind by turning a blind eye to what matters.",
          },
          // TODO Fix the -20 issue that is superscripted
          {
            text: "How, then, does the Bible approach the emotion of anger? James 1:19, 1:20 says, “Know this, my beloved brothers: let every person be quick to hear, slow to speak, slow to anger; for the anger of man does not produce the righteousness of God.” In addition, the book of Ecclesiastes states not to be “... quick in your spirit to become angry, for anger lodges in the heart of fools.” 2 It may be helpful, at this point, to turn toward some Biblical characters who dealt with anger and the pride that rides alongside it.",
          },
        ],
      },
      {
        subtitle: "The Ugly Potential",
        paragraphs: [
          {
            text: "It does not take long to find an extreme example of the nature of anger and pride in the Bible. Cain and Abel is the story of two brothers giving an offering to God. Abel, the younger brother, gave God the premium, first-born sheep from his flock, whereas Cain brought “...the fruit of the ground,” 3 or the leftovers per se. God then gives attention and esteem to Abel’s wholesome gift while showing little respect toward Cain and his fruits. Cain becomes infuriated; God warns Cain, saying, “Why are you angry, and why has your face fallen? If you do well, will you not be accepted? And if you do not do well, sin is crouching at the door. Its desire contrary to you, but you must rule over it.” Ignoring God’s warning, Cain gives into his pride and anger and kills his brother Abel.4",
          },
          {
            text: "Mark Twain once said, “Temper is what gets most of us in trouble. Pride is what keeps us there.” The story of Cain is a manifestation of this idea. God, full of grace, allows Cain to turn from his anger, but instead, he follows through with his vindictive plan. The anger Cain had kept him in a prideful ignorance towards God’s warnings, which would eventually leave him accursed by God. If not taken seriously, anger will ruin our lives and the lives of the people around us.",
          },
        ],
      },
      {
        subtitle: "Jonah",
        paragraphs: [
          {
            text: "The famous story of the minor prophet who survived the belly of a great fish, Jonah, shows us a man called to declare God’s judgment to an exceedingly evil city, Nineveh. Once called upon, Jonah flees in trepidation to the far ends of the known earth, Tarshish, because of Nineveh’s notorious reputation. While running away, the boat that Jonah was traveling on ran into a great storm; he, understanding this correctional sign, decides to throw himself overboard to save the crew. He would find himself in the belly of a great fish. Feeling hopeless, Jonah cries out to God. In response, God saves him, and Jonah continues his journey to Nineveh. When he arrives at the infamous city, he delivers warnings and instructions that Nineveh might repent. In a miraculous turn of events, the city turns from its evil ways, and God gives grace to the city. ",
          },
          {
            text: "Jonah’s immediate reaction to Nineveh's salvation was disgust; he became livid with God’s plan. Despite these ill feelings, Jonah acknowledges God’s goodness, saying: “Lord, is not this what I said when I was yet in my country? That is why I made haste to flee to Tarshish; for I knew that you are a gracious God and merciful, slow to anger, abounding in steadfast love, and relenting from disaster.”5 Jonah fully acknowledges that he wishes for Nineveh to be obliterated. The prophet is angry at God’s plan and goodness. ",
          },
          {
            text: "Christian apologist Frederick Buechner once said, “Of the seven deadly sins, anger is possibly the most fun. To lick your wounds, to smack your lips over grievances long past, to roll over your tongue the prospect of bitter confrontations still to come, to savor to the last toothsome morsel both the pain you are given and the pain you are giving back – in many ways it is a feast for a king. The chief drawback is that what you are wolfing down is yourself. The skeleton at the feast is you.”7 This rich depiction describes the result of a conglomeration of when one's pride and anger are given asylum. What do I mean by that? ",
          },
          {
            text: "Picture yourself as a harbor manager in the wake of an oncoming storm. A small boat arrives at your harbor, hoping for refuge. You allow the ship to enter the harbor and welcome the family into your home. Soon after, three more boats entered the harbor, loaded with people. You gladly take them in. Eventually, the boat owners take matters into their own hands and call out to any boat in the area, proclaiming that there is a safe place to stay while the ominous storm approaches; the boats arrive in masses. Then you realize there is nowhere to harbor any more of these boats or room for the refugees. Suddenly, the harbor has the responsibility to stand firm in a catastrophic storm while you hold the responsibility of taking what the boats brought with them. To make matters worse, there were still incoming boats that believed they would be safe in the arms of a good Samaritan, but they now had to pray that they would survive the likes of a storm. Like the boats and the refugees, pride and anger must be managed diligently. The harbor, one’s heart and mind, must be micromanaged in all circumstances, or else the ever-growing responsibility to deal with pride and anger will become overbearing. It will be detrimental to ourselves and the people around us when we let pride and anger have a foothold within us.",
          },
          {
            text: "The reader does not know how the story ends in the Book of Jonah; we only know that Jonah was fed up with his anger. Anger is a downward spiral that can cause resentment toward yourself, others, and God.",
          },
          {
            text: "Personally, anger has created turmoil within me for a long time. It has ruined relationships and dragged me down to some of the lowest, darkest places of my life. I was angry at myself for not being there for the people closest to me when they tried taking their own lives. I was angry at my church for being the reason the closest group of believers I had in my life vanished. I was angry at God for feeling like He abandoned me and left me here on this Earth to be in constant pain and misery. I was angry that it felt like God left me here to be alone. I can testify that my anger toward myself and the church did nothing but hurt my relationship with God and others. Anger has tarnished my perspective on how God views me, how others view me, and how I view myself. Anger has made forgiveness a daily, core challenge.",
          },
          {
            text: "Pastor John Bevere said, “Do not be afraid to allow the Holy Spirit to reveal any unforgiveness or bitterness. The longer you hide it, the stronger it will become and the harder your heart will grow. Stay tenderhearted.” 8 It is not worth the effort to constantly sit in anger and bitterness and let it fester. Do not let your harbor become clogged. Be diligent and careful with what you let in because a hardened heart will do you no good. I plead with you not to be like me, who, for a long time, refused to ask for help from God. I beg you to act and not let the anger grow like Jonah’s. Learn from Jonah. Learn from me. Relinquish your anger to the One who is Most High.",
          },
        ],
      },
      {
        subtitle: "What To Do With Anger",
        paragraphs: [
          {
            text: "As shown with Cain and Jonah, anger is a powerful emotion that the Bible calls us to address. Psalms 37:8 says, “Refrain from anger and forsake wrath,” which is an appeal to decide against anger wisely and to turn away from it completely. Jesus and Paul do not just recommend but command that Christians “must put them all away: anger, wrath, malice, slander, and obscene talk from your mouth.”9 Jesus emphasizes the importance of anger by saying, “You have heard that it was said to those of old, ‘You shall not murder; and whoever murders will be liable to judgment.’ But I say to you that everyone who is angry with his brother will be liable to judgment.”10",
          },
          {
            text: "So then, what are we to do with this anger? Jesus says, “First be reconciled to your brother,”11 and Paul says, “Be angry and do not sin; do not let the sun go down on your anger, and give no opportunity to the devil.”12 There is an immediate call to admit to your faults and shortcomings and be willing to forgive the other party. This will most likely look different for every situation, so the encouragement would be to honestly ask yourself what it would take to start forgiving and then to start to act on it immediately.",
          },
          {
            text: "Reframing how we react is a way to understand God’s grace. It also recognizes where Christians should seek God’s guidance. Pastor Warren W. Wiersbe said:",
          },
          {
            text: "What we need today is not anger but anguish, the kind of anguish that Moses displayed when he broke the two tablets of the law and then climbed the mountain to intercede for his people, or that Jesus displayed when he cleansed the temple and then wept over the city. The difference between anger and anguish is a broken heart. It’s easy to get angry, especially at somebody else’s sins; but it’s not easy to look at sin, our own included, and weep over it.13",
            type: "quote",
          },
          {
            text: "Maybe we as Christians should learn not to be angry at unmet expectations or frustrated at the mistakes of others. Instead, we should learn to empathize. Once we understand that we can hurt others, we acknowledge and accept that we are flawed. Afterward, we can anguish over all the people we let down and sinned against because of our flawed nature; Romans 3:23 testifies to this. Our acknowledgment and acceptance of our flawed nature leads to anguish and forgiveness. Once we understand our shortcomings, it is much easier to be willing to give up our prideful stance of anger. In that, we can look to God for forgiveness. 1 John 1:9 says, “if we confess our sins, He is faithful and just to forgive us our sins and to cleanse us from all unrighteousness.” But we have to forgive others first. Mark 11:25 states, “...whenever you stand praying, forgive if you have anything against anyone, so that your Father also who is in heaven may forgive you your trespasses.” These are actionable steps that must happen to move forward. Please do not take them lightly, but rather start the process of forgiveness as soon as possible. ",
          },
        ],
      },
      {
        subtitle: "Questions To Ask Yourself",
        paragraphs: [
          {
            text: "●	What situations have caused you anger?",
            type: "question",
          },
          {
            text: "●	Who has caused you to be angry, past or present?",
            type: "question",
          },
          {
            text: "●	Do you feel your anger is justified? If so, how?",
            type: "question",
          },
          {
            text: "●	Who have you wronged this week? How did you wrong them? Have you apologzied yet? What has prevented you from apologizing?",
            type: "question",
          },
          {
            text: "●	Who have you not forgiven? Why have you not fogiven them? What can you do to begin the process of forgiveness?",
            type: "question",
          },
          {
            text: "●	What minor inconveniences tend to arouse anger?",
            type: "question",
          },
          {
            text: "●	How do you usually deal with frustration, bitterness, or anger? Is your way of dealing with these emotions healthy?",
            type: "question",
          },
        ],
      },
      {
        subtitle: "References",
        paragraphs: [
          {
            text: "1.	Climacus, J. (n.d.). Anger. In Christ Centered House of God. Retrieved October 21, 2024, from hhttps://christcenteredhouseofgod.org/anger/",
          },
          { text: "2.	Ecclesiastes 7:9" },
          { text: "3.	Genesis 4:2" },
          { text: "4.	Genesis 4:3-8" },
          { text: "5.	Jonah 4:2" },
          { text: "6.	Jonah 4:3" },
          {
            text: "7.	Buechner, F. (n.d.). Of the seven deadly sins, anger is possibly the most fun. In Wishful thinking: A seeker’s ABC. Retrieved October 21, 2024, from https://frederickbuechner.squarespace.com/quote-of-the-day?offset=1516449600606",
          },
          {
            text: "8.	John, B. (n.d.). Quote from The Bait of Satan: Living free from the deadly trap of offense. In Goodreads. Retrieved October 21, 2024, from https://www.goodreads.com/work/quotes/29986-the-bait-of-satan-living-free-from-the-deadly-trap-of-offense",
          },
          { text: "9.	Colossians 3:8" },
          { text: "10.	Matthew 5:21-22" },
          { text: "11.	Matthew 5:24" },
          { text: "12.	Ephesians 4:26" },
          {
            text: "13.	13.	Wiersbe, W. W. (1991). The integrity crisis (pp. 75-76). Thomas Nelson Publishers.",
          },
        ],
      },
    ],
  },
  // Chapter 4
  {
    name: "Chapter 4: Authenticity's Role in The Christian Walk",
    type: "sincerly speaking",
    content: [
      {
        paragraphs: [
          {
            text: "Some of the strongest relationships that one will have in his or her lifetime are the ones that involve some level of intimacy. In order to develop a strong, healthy relationship, two people must spend time with one another. Time is a necessity in relationship building. Additionally, a level of honesty and transparency acts as a catalyst for creating a thriving relationship. To build an authentic relationship practically, one must be transparent, sincere, give honest feedback, and be willing to ask for help. Obviously, community and relationships are essential to everyone in varying degrees and forms. However, when it comes to the Christian walk, how important is establishing a relationship with God? The relational aspect of God is a centerpiece of the Christian faith, and it should be expressed firmly and in detail within Christian circles.",
          },
        ],
      },
      {
        subtitle: "The Psalms",
        paragraphs: [
          {
            text: "One primary way of communicating with God is by talking to Him. Some speak to God out loud, some in their head, and others through journaling. All are valid forms of expression. When looking at the Bible, there are multiple examples that illustrate communication with God. The largest book in the Bible, consisting of 150 chapters, Psalms is a collection of profound, honest writings to God. Psalm 6, written by David, has comments like, “Be gracious to me, O Lord, for I am languishing; heal me, O Lord for my bones are troubled,”1 and, “I am weary with my moaning; every night I flood my bed with tears; I drench my couch with weeping. My eye wastes away because of grief.”2 Then, in another Psalm, we read: “Out of the depths I cry to you, O Lord! O Lord, hear my voice!”3 These stanzas have “praise and prayer requests” elements like a daily Bible devotional, but there is more to them. With phrases like, “I am languishing … I am weary with my moaning,” and “Out of the depths I cry to you,” there is a level of transparency and humility that is more pronounced. To say, “Heal me, for my bones are troubled,” does not alone carry the same weight as comments like: “I am languishing,” “I am weary,” and “I drench my couch with weeping.” Those emotional descriptors add sincerity to the writings and illustrate the Psalmist’s heartfelt plea.",
          },
          {
            text: "Psalms 88, a passage known for its depressing tone, is a beautiful illustration of an author explaining to God how he is doing and demonstrating his sincere relationship with God. At the beginning of Psalms 88, the author writes: “O Lord, God of my salvation…”4 proclaiming that he still trusts God with his salvation. However, throughout the rest of the passage, the author poses questions like, “O Lord, why do you cast my soul away? Why do you hide your face from me?”5 and “[is] your faithfulness in Abaddon?”6 It appears as if the author feels like they have been cast away from God, shunned, and lost in a hellish pit. The author goes as far as to write, “You have put me in the depths of the pit, in the regions dark and deep. Your wrath lies heavy upon me, and you overwhelm me with all your waves.”7 This exemplifies earnest expression and communication with God. ",
          },
          {
            text: "David, after he committed adultery, responded to God in the only way he knew. In David’s confession, he says, “Have mercy on me, O God. … Wash me thoroughly from my iniquity, and cleanse me from my sin! For I know my transgressions, and mine are ever before me. Against you, you only, have I sinned and done what is evil in your sight.”8 To admit one’s transgressions is to humble oneself to the point where you cry out for help. The level of honesty it takes to admit one’s imperfections and transgressions, like David did, cannot be understated. ",
          },
          {
            text: "So why are these Psalms significant? The authors let their hearts pour out, expressing exactly how they feel toward themselves and God. For the authors to convey such emotion toward God is like reaching out one’s hand and saying, “I need you. I cannot do this on my own. I am struggling, and I am trusting you with this hurt.” At its root, this kind of vulnerability is a form of trust and love. These few examples, among many others, demonstrate authenticity and intimacy with God.",
          },
        ],
      },
      {
        subtitle: "God's Response",
        paragraphs: [
          // TODO Fix the '' vs " issue
          {
            text: "Numerous examples within the Bible exist of individuals or groups of people having a concurrent conversation with God or Christ. In Mark 9, directly after the transfiguration, Jesus heals a boy with an “unclean Spirit.” As Jesus approaches the crowd of people arguing, the boy's father comes to Jesus and asks, ''If you can do anything, have compassion on us and help us.'' Jesus responds, “If you can? Anything is possible for one who believes.” The father responds immediately, saying, “I believe; help my unbelief.” Shortly after, Jesus rebukes the spirit and returns the boy to health.9 Jesus is meeting the father’s strongest desire, and the father is about as honest as possible when responding to Jesus. He is expressing doubt in the relationship and is willing to admit that he does not have complete trust.",
          },
          {
            text: "One can also look at the story in 1 Kings 19. Elijah, through the power of God, had just defeated the Prophets of Baal and had them killed. In retaliation, Queen Jezebel sought to kill all the prophets of God, including Elijah. After running away, Elijah rests and asks God to let him die. In response, God sustains him for the next forty days to keep him moving forward.10 In summary, God responds to Elijah's needs after Elijah admits his emotional state.",
          },
          {
            text: "Job, after losing almost all his family and earthly possessions, becomes confused and upset. He justifiably responds by lamenting his birth. He says comments such as, “Let the day perish on which I was born,”11 and, “Why did I not die at birth,”12 which he would then reinstate by saying, “Oh that I might have my request, and that God would fulfill my hope, that it would please God to crush me, that he would let loose his hand and cut me off! This would be my comfort; I would even exult in pain unsparing, for I have not denied the words of the Holy One.” 13 As the book continues, Job debates with others about what God has done. He bounces back and forth between grief and praise towards God as he wrestles with his emotions and debates with others. God responds by displaying His authority and power by conversing with Job and showing him unexplainable marvels. God then gives Job more than before, demonstrating His grace and power. ",
          },
          {
            text: "Job, Elijah, and the father of the possessed boy all spoke and acted in a manner that was honest and authentic. God, in turn, responded uniquely and met the wants and needs of each individual. It is necessary to state that this is not advocating for the prosperity gospel. The stories mentioned are not arguments that being honest with God results in a shower of blessings. It is, however, a claim that He respects genuine, heartfelt emotions brought to Him. Not only is it respected, but God wants it because it establishes a newfound level of intimacy with him. The Psalms support this claim with an endless outpouring of heartfelt emotion. The writers of the Psalms believed it was important enough to express their feelings, whether joy, grief, anger, love, or gratitude. The Bible has included these stories and poetry to show that intimacy with God is important and that having a genuine relationship with God is possible.",
          },
        ],
      },
      {
        subtitle: "The Importance of Authenticity",
        paragraphs: [
          {
            text: "If Christians seek to grow in their faith and relationship with God, they must continually work to build a relationship with Him. The foundation of any relationship begins with an honest conversation. An individual's relationship with God is no different. It is built by being honest and developing trust between the two parties. Trust is a form of love. If I ask a friend to do a task for me or tell them something personal, I am implying that I trust and respect them. If one looks at the definition of the word respect, one will find that it is “a feeling of great approval and liking.”14 With more respect comes more care and admiration for the individual. To love is to care deeply about something or someone. Therefore, if I deeply trust someone, I deeply care about them. From the opposite perspective, do I show love to my spouse if I refuse to entrust them with important information, decisions, or details? Is it not disingenuous to avoid entrusting them with important aspects of life?",
          },
          {
            text: "To go one step further, the argument could be made that being sincere in our relationship with God is a command. We read in James 4, “Draw near to God, and he will draw near to you,”15 and, “Humble yourselves before the Lord, and he will exalt you.”16 These are more than just a suggestion. Part of what it looks like to draw near to God requires obeying His words, like humbling oneself. This consists of acknowledging how one is honestly doing and recognizing that the Christian life cannot be lived alone. It is saying, “I believe; help my unbelief,”17 “Out of the depths I cry to you, O Lord! O Lord, hear my voice!”18 and, “Be gracious to me, O Lord, for I am languishing; heal me, O Lord, for my bones are troubled.”19 These sincere, heartfelt acknowledgments to God of our dependency upon Him exemplify humility.",
          },
          {
            text: "God’s desire to fellowship with those made in His image is seen in James 4:5 when it says, “He yearns jealously over the spirit that He has made to dwell in us.” In one’s relationship with God, a Christian can express praise, frustration, and despair to the Lord. He wants to hear His people, so being honest with Him is an enormous step in the right direction. Forthrightness is humbling and draws one near to God, whether through journaling, solemn prayer, or an outcry of raw emotions to Him. His primary desire is a genuine relationship with His children. God longs to hear you and relishes in our desire to be close to Him. It is important to regularly examine our hearts and seek to be more authentic in our communication with the Lord.",
          },
        ],
      },
      {
        subtitle: "Questions To Ask Yourself",
        paragraphs: [
          {
            text: "●	Are you being honest with yourself about how you are doing?",
            type: "question",
          },
          {
            text: "●	Are you being honest with God about your spiritual and emotional well-being?",
            type: "question",
          },
          {
            text: "●	When is the last time you have been genuinely honest with your family or friends?",
            type: "question",
          },
          {
            text: "●	Why are you hesitant to be honest with others?",
            type: "question",
          },
          {
            text: "●	When is the last time you have been genuinely honest with God?",
            type: "question",
          },
          {
            text: "●	Why are you hesitant to be honest with God?",
            type: "question",
          },
          {
            text: "●	What is one honest admission you can tell God right now?",
            type: "question",
          },
          {
            text: "●	What fear, anger, frustration, pride, or lack of forgiveness do you need to be honest about?",
            type: "question",
          },
        ],
      },
      {
        subtitle: "References",
        paragraphs: [
          { text: "1.	Psalm 6:2" },
          { text: "2.	Psalm 6:6-7" },
          { text: "3.	Psalm 130:1-2" },
          { text: "4.	Psalm 88:1" },
          { text: "5.	Psalm 88:14" },
          { text: "6.	Psalm 88:11" },
          { text: "7.	Psalm 88:6-7" },
          { text: "8.	Psalm 51:1-4" },
          { text: "9.	Mark 9:14-28" },
          { text: "10.	1 Kings 19:4-8" },
          { text: "11.	Job 3:3" },
          { text: "12.	Job 3:11" },
          { text: "13.	Job 6:8-10" },
          {
            text: "14.	Merriam-Webster. (n.d.). Respect. In Merriam-Webster.com dictionary. Retrieved October 22, 2024, from https://www.merriam-webster.com/dictionary/respect",
          },
          { text: "15.	James 4:8" },
          { text: "16.	James 4:10" },
          { text: "17.	Mark 9:24" },
          { text: "18.	Psalm 130:1-2" },
          { text: "19.	Psalm 6:2" },
        ],
      },
    ],
  },
  // Chapter 5
  {
    name: "Chapter 5: Patience and Perseverance",
    type: "sincerly speaking",
    content: [
      {
        paragraphs: [
          {
            text: "In a day and age where information, access, and answers are so readily available, the art of patience has been lost. To confirm this hypothesis, look no further than yourself.  Do you grow irritated at the car driving the legal speed limit all because you want to go five miles an hour faster? How quickly do you become annoyed because the internet will not load the web page “fast enough”? Are you frustrated by people who don’t respond to your texts immediately? These are just a few examples most people have probably experienced. These are minimal inconveniences in the grand scheme of things, yet they can cause someone’s blood to boil in extreme cases. According to Merriam-Webster, patience is the ability to “bear pains or trials calmly or without complaint” or to manifest “forbearance under provocation or strain.”1 Based on these definitions and the examples, patience seems more passive.",
          },
          {
            text: "Perseverance, on the other hand, is more active and actionable. Merriam-Webster defines the word as a “continued effort to do or achieve something despite difficulties, failure, or opposition”2 in other words, steadfastness or endurance. Both perseverance and patience go hand-in-hand with each other. If one perseveres, one's patience will grow in future situations. One will be able to patiently tolerate the inconveniences that life throws at them in the future.",
          },
          {
            text: "If one is genuinely patient, perseverance becomes easier because one can respond rationally and maturely for a more significant period. For example, a college student has a challenging semester filled with projects, reports, tests, and work. The student, frustrated at the inconvenient workload, successfully pushes through the semester. As he approaches the following academic year, he worries about his busy schedule, but now he can look back and recognize God’s provision during the previous difficult semester. Remembrance of that should be enough encouragement that when the student is hard-pressed again, he will not be as anxious or distraught. The confidence in his ability to be unphased and push forward should help him in his future endeavors. In theory, this dynamic duo of virtues should work together to enable the other to strengthen.",
          },
        ],
      },
      {
        subtitle: "The Calling of Perseverance",
        paragraphs: [
          {
            text: "The Apostle Paul talks about our calling as Christians to endure the race before us, saying, “Do you not know that in a race all runners run, but only one receives the prize? So run that you may obtain it.”3 To specify what “it” is, we can look at many passages throughout the New Testament. The prize or goal is “... the upward call of God in Christ Jesus,”4 or “... the crown of life.”5 It is the complete union of us and God. Now, this race is no cakewalk as Christ gives a warning, saying, ",
          },
          {
            text: "Then they will deliver you up to tribulation and put you to death, and you will be hated by all nations for my name’s sake. And then many will fall away and betray one another and hate one another. And many false prophets will arise and lead many astray. And because lawlessness will be increased, the love of many will grow cold. But the one who endures to the end will be saved.6",
            type: "quote",
          },
          {
            text: "Christ even warns us of our own family turning against us, saying, “Brother will deliver brother over to death, and the father his child, and children will rise against parents and have them put to death, and you will be hated for my name’s sake. But the one who endures to the end will be saved.”7",
          },
          {
            text: "While these warnings are frightful, there is a promise: “... the one who endures to the end will be saved.” The Biblical author James echoes Christ when he says, “Blessed is the man who remains steadfast under trial, for when he has stood the test he will receive the crown of life, which God has promised to those who love him.”8 From these passages, perseverance seems to be an actionable form of love. To be enduring, no matter the circumstance, is a form of love. If hope in Christ is the foundation of all our decisions, actions, and motives, then pursuing Christ amid endless trials and tense feelings of despair is one of the purest forms of love.",
          },
          {
            text: "What, then, is the action of endurance? The answer to that does not seem to be some form of a pause while waiting for God to act, but rather, “...press[ing] on toward the goal.”9 This looks like “...stand[ing] firm in the faith…”10 and meditate on the gospel's truth. We must understand the promises that Christ has fulfilled and the purpose that He has for us. The author of Hebrews encourages us to “hold fast the confession of our hope without wavering, for he who promised is faithful.”11 Understanding the hope we have in Christ’s promises is necessary to standing firm in the faith. In addition, we are called to “...not grow weary of doing good.”12 As previously mentioned, part of what doing good looks like is acting justly, being supportive and caring, and showing grace and love without prejudice through Christ. To persevere in the faith means to continue the life of Christ-like servitude.",
          },
        ],
      },
      {
        subtitle: "Count it all Joy",
        paragraphs: [
          {
            text: "It is not a surprise that life is full of trials and hardships. Jesus attests to that, as mentioned earlier. One can see it in Paul’s life when he tells of his endless adversity for the sake of Christ:",
          },
          {
            text: "…far more imprisonments, with countless beatings, and often near death. Five times I received at the hands of the Jews the forty lashes less one. Three times I was beaten with rods. Once I was stoned. Three time I was shipwrecked; a night and a day I was adrift at seas; on frequent journeys, in danger from rivers, danger from robbers, danger from my own people, danger from Gentiles, danger in the city, danger in the wilderness, danger at sea, danger from false brothers; in toil and hardship, through many sleepless night, in hunger and thirst, often without food, in cold and exposure. And apart from other things there is the daily pressure on me of my anxiety for all the churches.13",
            type: "quote",
          },
          {
            text: "Henry Ward Beecher, an 1800s social reformer, said, “we are always in the forge, or on the anvil; by trials, God is shaping us for higher things.” Beecher’s words could not ring more accurately as they are directly inspired by the words of James 1:3-4: “... for you know that the testing of your faith produces steadfastness. And let steadfastness have its full effect, that you may be perfect and complete, lacking in nothing.” Hopefully, the result of the testing of one’s faith is someone closer to Christ and someone who can reflect God’s glory better. ",
          },
        ],
      },
      {
        subtitle: "Putting it All Together",
        paragraphs: [
          {
            text: "We are given the indisputable fact that life's trials will happen. Within these trials, we are commanded to continue in the good works of the Lord. We are also given a clear command of how we approach life with three simple phrases from Paul: “Rejoice in hope, be patient in tribulation, be constant in prayer.”14",
          },
          {
            text: "To rejoice in hope is to understand the goodness of what God has done for us. It is to realize that Christ died on the cross for us so that we could be with him for eternity. The constant pursuit of Christ during difficult circumstances is worth it. We, as Christians, have hope in something assured. Simply put, that is something to be eternally joyful about.",
          },
          {
            text: "While experiencing tribulation, we must act pleasingly to God. Patience and perseverance are critical parts of overcoming the trials of life and our walk with Christ. As mentioned earlier, perseverance partners with patience. Utilizing these two virtues can demonstrate an outpouring of love to those around us. No matter the circumstance, we can now be unphased because we understand our calling in Christ and hope in it.",
          },
          {
            text: "Throughout it all, there should be constant reflection and communication with God. Prayer’s role in life cannot and should not be understated as it plays a vital role in the Christian walk. Prayer is communication with the One who understands. It is a way to express the joy found in hope. It is a practical way to communicate anger or frustration while patiently persevering through a trial. It is a beautiful way to process every facet of life to God.",
          },
          {
            text: "Patience and perseverance are essential in our faith journey for an authentic relationship with God. The honest outpouring of our hearts, even during our suffering and doubts, should be shared with our patient, unchanging, ever-loving Father. Understanding and believing that God’s timing is perfect should put us in a position to trust God. Hopefully, our trust in Him will keep us transparent and steadfast in our faith. And, as we proceed, our faithful pursuit of God will result in us doing the good works we are called to do.",
          },
        ],
      },
      {
        subtitle: "Questions To Ask Yourself",
        paragraphs: [
          {
            text: "●	How would you describe your ability to be patient?",
            type: "question",
          },
          {
            text: "●	What type of inconveniences bother you?",
            type: "question",
          },
          {
            text: "●	What makes you frustrated easily?",
            type: "question",
          },
          {
            text: "●	How do you respond when you are impatient? ",
            type: "question",
          },
          {
            text: "●	How do you respond to difficulty? Do you retreat? Do you give up?",
            type: "question",
          },
          {
            text: "●	Looking back on your life, are you grateful for the difficult times?",
            type: "question",
          },
          {
            text: "●	When have you experienced joy?",
            type: "question",
          },
        ],
      },
      {
        subtitle: "References",
        paragraphs: [
          {
            text: "1.	Merriam-Webster. (n.d.). Patience. In Merriam-Webster.com dictionary. Retrieved October 22, 2024, from https://www.merriam-webster.com/dictionary/patience",
          },
          {
            text: "2.	Merriam-Webster. (n.d.). Perseverance. In Merriam-Webster.com dictionary. Retrieved October 22, 2024, from https://www.merriam-webster.com/dictionary/perseverance",
          },
          { text: "3.	1 Corinthians 9:24" },
          { text: "4.	Philippians 3:14" },
          { text: "5.	James 1:12" },
          { text: "6.	Matthew 24:9-13" },
          { text: "7.	Matthew 10:21-22" },
          { text: "8.	James 1:12" },
          { text: "9.	1 Corinthians 16:13" },
          { text: "10.	Hebrews 10:23" },
          { text: "11.	Galatians 6:9" },
          { text: "12.	1 Thessalonians 3:13" },
          { text: "13.	2 Corinthians 11:23-28" },
          {
            text: "14.	Beecher, H. W. (n.d.). We are always in the forge, or on the anvil; by trials, God is shaping us for higher things. In Access Inspiration. Retrieved October 21, 2024, from https://accessinspiration.org/topic/trials-testing/",
          },
          { text: "15.	Romans 12:12" },
        ],
      },
    ],
  },
  // Chapter 6
  {
    name: "Chapter 6: Gratitude",
    type: "sincerly speaking",
    content: [
      {
        paragraphs: [
          {
            text: "Is it normal to doubt God’s goodness? Is our pride acceptable? Is it justifiable to be angry? When I feel nothing from God, how do I lean into Him? All these questions asked to have a common theme among them. Simply put, they result from us turning a blind eye to the importance of gratitude. ",
          },
          {
            text: "Gratitude, or thankfulness, is acknowledging and understanding the opportunities, gifts, and love one has received. Gratitude is the orientation we are called to, no matter the circumstance. There is a reason that Paul says, “rejoice always, pray without ceasing, give thanks in all circumstances; for this is the will of God in Christ Jesus for you”1 and then again when he says, “Do not be anxious about anything, but in everything by prayer and supplication with thanksgiving let your requests be made known to God.”2 In every circumstance, gratitude should be expressed; there is no conditional statement in those verses. So, we are told to be gracious, but why? Gratitude is a reframed version of what we think of God and how we view ourselves in relation to Him.",
          },
          {
            text: "Gratitude toward God acknowledges what He has done in our lives, no matter the circumstances. It praises that we depend on God to provide for us in all aspects of our lives. It is an act of remembrance for what God has done for us. One example of this can be found in the Old Testament. During the process of Israel being led into the promised land, Joshua, the leader of the Israelites, is told by God to pick up stones out of the Jordan. These stones are supposed to be a remembrance that “‘...Israel passed over this Jordan on dry ground.’ … God dried up the waters of the Jordan for [Israel] until [Israel] passed over, as the [Lord God] did to the Red Sea, which he dried up for [Israel] until [Israel] passed over… that [Israel] may fear the [Lord God] forever.”3 The stones are a reminder of the circumstances the Lord had the power to bring them through. This physical manifestation of gratitude is a way to recognize how God provided for them.",
          },
          {
            text: "Do we not understand the gift of eternal life? That should be all the reasons we need to be thankful. Do we not understand how sinful and undeserving we are of such a gift? That should be all the reason we need to realize that we are desperately dependent on Him. Author and speaker Jim Elliff said in an article about gratitude that “the problem with being thankful is not much one of the manners as it is of alertness to the facts, that is, simply having open eyes to what is true. And it is true that you and I deserve nothing good. No, more than that, we deserve everything bad – an eternity in hell.”4",
          },
          {
            text: "In all situations, we have a God who allows us to love Him and be with Him for eternity. The book of John famously says, “For God so loved the world, that He gave His only Son, that whoever believes in Him should not perish but have eternal life. For God did not send His Son into the world to condemn the world, but in order that the world might be saved through Him.”5 This, in and of itself, should be worth all our praise, admiration, and gratitude. No matter the circumstances, praise God because He is the rock of our salvation. There are no better examples of praising God for who He is than those found in the Psalms: “let us make a joyful noise to the rock of our salvation!”6 “God is our salvation.”7 “You are my Father, my God, and the Rock of my salvation.”8",
          },
        ],
      },
      {
        subtitle: "All The More",
        paragraphs: [
          {
            text: "Despite our destitute and sinful state, we have a God who will continue to forgive us. Paul asks believers, “What shall we say then? Are we to continue in sin that grace may abound?”9 Incredibly, this question implies If we mess up, God will still give grace no matter how badly or how often we sin. This is a testament to God's patience and forgiveness. It is important to note that this is not an indictment to keep sinning as Paul responds to his question by saying, “By no means! How can we who died to sin still live in it?”10 We are not meant to be held captive to sin anymore but to turn away from it. The point is that God's endless grace is the reason to express endless thanksgiving. If given the blessing of salvation, do we deserve anything else? So then, when given any gift, opportunity, or chance to grow, it is a blessing.",
          },
          {
            text: "Whether it be health, an answered prayer, a fulfilling community, a stable job, an opportunity to serve, or a trial, it does not matter. There is a reason to be thankful for what God has done and is doing. As C.S Lewis says, “We ought to give thanks for all fortune: if it is good, because it is good, if bad, because it works in us patience, humility, and the contempt of this world and the hope of our eternal country.”11",
          },
          {
            text: "Objectively speaking, the opportunity to express gratitude is present in all circumstances. If you are struggling to find a reason to be thankful, then I would highly encourage you to stop and ask yourself why. That hesitancy is likely the doubt of God’s goodness, our pride and anger eating away at our hearts, longing for the impossible and destructive desire to be in complete control, or our lack of trust in God’s timing. More than likely, it's all the above. ",
          },
          {
            text: "While these warnings are frightful, there is a promise: “... the one who endures to the end will be saved.” The Biblical author James echoes Christ when he says, “Blessed is the man who remains steadfast under trial, for when he has stood the test he will receive the crown of life, which God has promised to those who love him.”8 From these passages, perseverance seems to be an actionable form of love. To be enduring, no matter the circumstance, is a form of love. If hope in Christ is the foundation of all our decisions, actions, and motives, then pursuing Christ amid endless trials and tense feelings of despair is one of the purest forms of love.",
          },
          {
            text: "I tell you I understand and that you are not alone, but that does not make it right. However, we are called to be thankful in every situation because God is gracious toward us in every situation. There are no exceptions. Understanding who we are fundamentally should absolutely break us, and that is a perfect place to start because it is all the more reason to be thankful for the Rock of our Salvation. ",
          },
        ],
      },
      {
        subtitle: "Questions To Ask Yourself",
        paragraphs: [
          {
            text: "●	What is one thing you are grateful for today?",
            type: "question",
          },
          {
            text: "●	Who is one person you are grateful for? Why are you grateful for them? Have you ever expressed your gratitude to them?",
            type: "question",
          },
          {
            text: "●	Do you take the time out of your day to be thankful?",
            type: "question",
          },
          {
            text: "●	Have you expressed any gratitude to God? If not, why?",
            type: "question",
          },
          {
            text: "●	What are you hesitant to be grateful for? Why?",
            type: "question",
          },
        ],
      },
      {
        subtitle: "References",
        paragraphs: [
          { text: "1.	1 Thessalonians 5:16-18" },
          { text: "2.	Philippians 4:6" },
          { text: "3.	Joshua 4:22-24" },
          {
            text: "4.	Eliff, J. (2021, November 22). But I don’t feel thankful. CCW Today. Retrieved October 22, 2024, from https://www.ccwtoday.org/2022/11/but-i-dont-feel-thankful/",
          },
          { text: "5.	John 3:16-17" },
          { text: "6.	Psalm 95:1" },
          { text: "7.	Psalm 68:19" },
          { text: "8.	Psalm 89:26" },
          { text: "9.	Romans 6:1" },
          { text: "10.	Romans 6:2" },
          {
            text: "11.	Lewis, C. S. (1990). We ought to give thanks for all fortune: if it is good, because it is good, if bad, because it works in us patience, humility, and the contempt of this world and the hope of our eternal country (W. Martindale & J. Root, Eds.). In The quotable Lewis (p. 579). Tyndale. Retrieved October 22, 2024, from https://generositymonk.com/2015/11/25/c-s-lewis-give-thanks-for-the-good-and-bad/",
          },
        ],
      },
    ],
  },
  // Chapter 7
  {
    name: "Chapter 7: Endling in Practicality",
    type: "sincerly speaking",
    content: [
      {
        paragraphs: [
          {
            text: "What does an authentic and practical Christian life look like if you are questioning God? It means coming before God humbly and admitting your doubts. Despite your hesitations, continue to lean into God by serving the community right in front of you for the glory of God. Address any anger and bitterness head-on, but ensure you do so while expressing gratitude. Psalms 50:23 says, “the one who offers thanksgiving as his sacrifice glorifies me.” In dedicating time to gratitude, we begin to understand God's endless love for us. As A.W. Tozer says, “Gratitude is an offering precious in the sight of God, and it is one that the poorest of us can make and be not poorer but richer for having made it.” ",
          },
          {
            text: "Disappointment with how you feel about God or others should not be a dictation of direction but an indicator to push into Christ. With pinpoint accuracy, C.S. Lewis offers insight into the importance of service to people around us, saying, “do not waste time bothering whether you ‘love’ your neighbor; act as if you did. As soon as we do this, we find one of the great secrets. When you are behaving as if you love someone you presently come to love him.”2 Lewis’ comments also apply to God. When we learn how to love others and to serve God, we will learn to see the character of God. That, in and of itself, is a cure for pride, anger, and bitterness. ",
          },
          {
            text: "Anyone could give you an apologetic claim to address your doubt in God. Anyone could research philosophical arguments to address the goodness of God. While they should be addressed, do not let them stand in your way of doing what we are called to do. So, my encouragement to you is to be honest with God about where you are; He can handle it. You are not alone in this race; other Christians are running with you. Take the time to be thankful for every situation in life. If you feel like you cannot be grateful, ponder why. We serve a God who wants to work in us. From the numerous testimonies throughout the Bible and the endless stories we have from Christians. Run the race in front of you. It will be worth it.",
          },
        ],
      },
      {
        subtitle: "References",
        paragraphs: [
          {
            text: "1.	Tozer, A. W. (n.d.). Gratitude is an offering precious in the sight of God, and it is one that the poorest of us can make and be not poorer but richer for having made it. In The Sovereign (blog). Retrieved October 22, 2024, from https://thesovereign.wordpress.com/2017/11/23/a-w-tozer-on-thanksgiving/",
          },
          {
            text: "2.	Lewis, C. S. (1998). Charity. In Mere Christianity (pp. 131). essay, HarperCollins.",
          },
        ],
      },
    ],
  },
  // Chapter 8
  {
    name: "Afterword",
    type: "sincerly speaking",
    content: [
      {
        paragraphs: [
          {
            text: "I mentioned earlier that this project initially started as a way for me to process a fascinating theme I had seen in the Bible: authenticity. Authenticity was a critical factor in what turned my faith around. Whether it be how I communicated with God or with the Christians around me, it completely transformed my approach to life. In reality, the process of writing about authenticity became a way to formulate my words and explain why I believe what I believe. This project taught me two fundamental lessons.",
          },
          {
            text: "Lesson one was how to examine the Bible. For the first time, I had to go through the Bible, examine what I had learned, and dive deeper into everything to gain understanding. Additionally, it taught me how to stay honest. I often wanted to use a Biblical story that I believed would fit the narrative or idea I wanted to discuss; however, I had to catch myself from misconstruing a story's original message. The writing process kept me honest and humbled me quite frequently. It forced me to ask others around me to keep me accountable. I tried my best to be as truthful as possible on all fronts. ",
          },
          {
            text: "Secondly, this small book became a sermon to me. I find it no coincidence that the subjects the book covers are some of the six standout complexities I am currently working through. There were countless times God used the writing process to call me out about how I had behaved. For example, when writing about authenticity, I began to feel immense chronic pain. While sitting and writing, I began to tear up, typing the verses of Psalm 6:2, “Be Gracious to me, O Lord, for I am languishing; heal me, O Lord, for my bones are troubled.” I quickly realized that I avoided doing what I was passionately discussing. The original comment I mentioned at the beginning would continue to ring true. I was not questioning God’s goodness in others' lives, but I was neglecting the idea that I had a Father, like God, who wanted to hear me. Then, I was left with a choice I would consistently have to make. Will I choose to put God and others above myself? I am not saying I am anywhere near being “good” at making the correct choice, but I am much more aware now. I hope and pray that the Lord works to humble me and works to humble the hearts of those longing for Him. ",
          },
        ],
      },
    ],
  },
];
