import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import WritingsPage from "./pages/WritingsPage";
import WritingDetail from "./pages/WritingDetail"; // Import the WritingDetail component
import About from "./pages/About";
import "./App.css";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/writings" element={<WritingsPage />} />
        <Route path="/writings/:writingName" element={<WritingDetail />} />{" "}
        {/* Add this route */}
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}

export default App;
